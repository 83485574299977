import Navbar from "./components/Navbar";
import Portfolio from "./components/Portfolio";
import Review from "./components/Review";
import Services from "./components/Services";

import { BiGroup } from "react-icons/bi";
import { FiMapPin } from "react-icons/fi";
import { AiOutlineFileSearch } from "react-icons/ai";

type ReviewType = {
  firstAndLastName: string;
  rating: string;
  positives?: string[];
  services?: string[];
  description: string;
  url: string;
};

function App() {
  const data = require("./assets/reviews.json");

  return (
    <div>
      <Navbar />
      <div className="py-32 md:py-40 px-10">
        <div className="flex flex-col justify-center items-center gap-10 bg-lightPurple p-10 rounded-lg text-white info-shadow max-w-[600px] mx-auto">
          <p className="text-4xl flex sm:flex-row flex-col gap-2 md:gap-4 items-center">
            <BiGroup />
            Who are we?
          </p>
          <p className="text-center text-xl sm:text-2xl">
            Electrical Contractor Operating around the West Midlands. Specialist
            in Bespoke Lighting design and Installation.
          </p>
        </div>
      </div>
      <hr className="border-white neon" />
      <div className="bg-electritian-1 bg-cover" id="services">
        <div className="p-12 sm:p-20 md:p-32 bg-black bg-opacity-70">
          <Services />
        </div>
      </div>
      <hr className="border-[1px] border-black h-[1px] neon" />
      <div
        className="py-24 px-10 bg-evenLighterPurple flex md:flex-row flex-col gap-20 md:gap-0 justify-around"
        id="work-area"
      >
        <div className="flex flex-col justify-center items-center bg-lightPurple gap-10 p-10 rounded-lg text-white info-shadow max-w-[600px]">
          <p className="text-3xl flex sm:flex-row flex-col gap-2 md:gap-4 items-center text-center">
            <FiMapPin />
            Where do we work?
          </p>
          <p className="text-center text-xl sm:text-2xl">
            We aim to cover the Majority of the Midlands. <br />
            24 hour emergency callout available.
          </p>
        </div>
      </div>
      <hr className="border-white neon" />
      <div id="gallery">
        <Portfolio />
      </div>
      <hr className="border-white neon" />
      <p
        className="text-white text-center md:text-5xl text-4xl pt-12 font-semibold flex flex-row items-center align-middle justify-center gap-2 md:gap-4"
        id="reviews"
      >
        <AiOutlineFileSearch />
        Reviews
      </p>
      <div className="py-24 md:px-24 bg-evenLighterPurple flex flex-row gap-20 md:gap-0 justify-between overflow-x-auto mx-auto">
        {data.data.map((review: ReviewType, index: number) => {
          return (
            <Review
              name={review.firstAndLastName}
              key={index}
              description={review.description}
              rating={review.rating}
              positives={review.positives}
              services={review.services}
              url={review.url}
            />
          );
        })}
      </div>
      <hr className="border-white neon" />
      <div className="p-10 flex flex-col gap-2 items-center justify-center bg-darkPurple">
        <img
          src="/images/location-pin.png"
          alt="location-pin"
          className="h-8"
        />
        <a
          className="cursor-pointer hover:text-black text-white text-xl text-center"
          href="https://goo.gl/maps/egvnXiyVCYeAJXzo8"
          target="_blank"
        >
          Sunnybank, ST18 9QP, Stafford, United Kingdom
        </a>
      </div>
    </div>
  );
}

export default App;
