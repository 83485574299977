import { HiLightBulb } from "react-icons/hi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

function Services() {
  return (
    <div className="bg-cream rounded-md p-10 py-16 max-w-[800px] mx-auto custom-shadow">
      <p className="text-center sm:text-3xl font-semibold flex flex-row items-center justify-center gap-4 md:text-4xl">
        <HiLightBulb />
        Services we offer
      </p>
      <hr className="text-black bg-black h-[2px] mt-4" />
      <div className="pt-5 flex flex-col text-center items-center sm:text-xl md:gap-3">
        <p className="md:flex flex-row items-center gap-2 md:text-2xl hidden">
          <IoMdCheckmarkCircleOutline />
          Additions and Alterations
        </p>
        <p className="flex flex-row items-center gap-2 md:text-2xl md:hidden">
          <IoMdCheckmarkCircleOutline />
          Additions
        </p>
        <p className="md:hidden">and Alterations</p>
        <p className="flex flex-row items-center gap-2 md:text-2xl">
          <IoMdCheckmarkCircleOutline />
          Rewiring
        </p>
        <p className="flex flex-row items-center gap-2 md:text-2xl">
          <IoMdCheckmarkCircleOutline />
          New Builds
        </p>
        <p className="flex flex-row items-center gap-2 md:text-2xl">
          <IoMdCheckmarkCircleOutline />
          PAT Testing
        </p>
        <>
          <p className="flex flex-row items-center gap-2 md:hidden">
            <IoMdCheckmarkCircleOutline />
            Fault Finding
          </p>
          <p className="md:hidden">and Rectification</p>
          <p className="flex-row items-center gap-2 hidden md:flex md:text-2xl">
            <IoMdCheckmarkCircleOutline />
            Fault Finding and Rectification
          </p>
        </>
        <p className="flex flex-row items-center gap-2 md:text-2xl">
          <IoMdCheckmarkCircleOutline />
          Data Cabling
        </p>
        <p className="flex flex-row items-center gap-2 md:text-2xl">
          <IoMdCheckmarkCircleOutline />
          Security Alarms
        </p>
        <p className="flex flex-row items-center gap-2 md:text-2xl">
          <IoMdCheckmarkCircleOutline />
          CCTV
        </p>
        <p className="flex flex-row items-center gap-2 md:text-2xl">
          <IoMdCheckmarkCircleOutline />
          Inspection and Testing
        </p>
        <p className="flex flex-row items-center gap-2 md:text-2xl">
          <IoMdCheckmarkCircleOutline />
          Fire Alarms
        </p>
        <p className="flex flex-row items-center gap-2 md:text-2xl">
          <IoMdCheckmarkCircleOutline />3 Phase Installations
        </p>
        <p className="flex flex-row items-center gap-2 md:text-2xl">
          <IoMdCheckmarkCircleOutline />
          Bespoke Lighting
        </p>
      </div>
    </div>
  );
}

export default Services;
