import { Rating } from "flowbite-react";
import { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";

type ReviewType = {
  name: string;
  rating: string;
  positives?: string[];
  services?: string[];
  description: string;
  url: string;
};

function Review({
  name,
  rating,
  positives,
  description,
  services,
  url,
}: ReviewType) {
  const [starsArray, setStarsArray] = useState<ReactNode[]>();

  useEffect(() => {
    let auxStarsArray: ReactNode[] = [];

    for (let i = 1; i <= 5; i++) {
      if (i <= Number(rating)) {
        auxStarsArray.push(<Rating.Star key={i} />);
      } else {
        auxStarsArray.push(<Rating.Star className="text-gray-400" key={i} />);
      }
    }

    setStarsArray(auxStarsArray);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-lightPurple md:gap-10 gap-6 md:p-10 p-8 rounded-lg text-white info-shadow w-[300px] md:w-[600px] flex-shrink-0 mx-8 md:mx-32">
      <a className="text-2xl cursor-pointer" href={url}>
        {name}
      </a>
      <Rating>{starsArray}</Rating>
      <hr className="border-[1px] w-full border-white" />
      <div>
        <p>{description}</p>
      </div>
      <div
        className={clsx(
          "flex-col mr-auto gap-2",
          positives && positives.length > 0 ? "flex" : "hidden"
        )}
      >
        <p className="font-semibold">Positives:</p>
        <div className="flex flex-row flex-wrap text-left gap-x-4 gap-y-2 text-gray-400">
          {positives &&
            positives.map((p, index) => {
              return (
                <p className="inline-flex" key={index}>
                  {p}
                </p>
              );
            })}
        </div>
      </div>
      <div
        className={clsx(
          "flex-col mr-auto gap-2",
          services && services.length > 0 ? "flex" : "hidden"
        )}
      >
        <p className="font-semibold">Services:</p>
        <div className="flex flex-row flex-wrap text-left gap-x-4 gap-y-2 text-gray-400">
          {services &&
            services.map((p, index) => {
              return (
                <p className="inline-flex" key={index}>
                  {p}
                </p>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Review;
