import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

function Navbar() {
  return (
    <nav className="flex flex-row justify-around items-center align-middle py-4 bg-lightPurple shadow-sm shadow-black w-full fixed z-50 border-b-black border-b-2">
      <a
        href="#"
        className="group hover:bg-cream rounded-md transition active:bg-creamDarker"
      >
        <img
          src="/images/logo-white.png"
          alt="Logo"
          className="h-10 md:h-16 group-hover:hidden"
        />
        <img
          src="/images/logo1.png"
          alt="Logo"
          className="h-10 md:h-16 group-hover:inline hidden"
        />
      </a>
      <div className="flex-row gap-40 text-white text-2xl hidden 3xl:flex">
        <a
          href="#services"
          className="hover:bg-cream hover:text-black transition p-2 rounded-md active:bg-creamDarker"
        >
          Services
        </a>
        <a
          href="#work-area"
          className="hover:bg-cream hover:text-black transition p-2 rounded-md active:bg-creamDarker"
        >
          Work Area
        </a>
        <a
          href="#gallery"
          className="hover:bg-cream hover:text-black transition p-2 rounded-md active:bg-creamDarker"
        >
          Gallery
        </a>
        <a
          href="#reviews"
          className="hover:bg-cream hover:text-black transition p-2 rounded-md active:bg-creamDarker"
        >
          Reviews
        </a>
      </div>
      <div className="flex flex-row md:gap-10 gap-16">
        <>
          <a
            href="mailto: CHDElectrics@gmail.com"
            className="text-white md:hidden text-2xl flex flex-row gap-4 text-center items-center active:bg-creamDarker"
          >
            <GrMail className="text-white sm:text-2xl md:text-2xl" />
          </a>
          <a
            href="mailto: CHDElectrics@gmail.com"
            className="hidden text-white md:text-2xl md:flex flex-row gap-4 text-center items-center rounded-lg hover:text-black group hover:bg-cream p-2 transition active:bg-creamDarker"
          >
            <GrMail className="text-white sm:text-xl md:text-2xl group-hover:text-black" />
            CHDElectrics@gmail.com
          </a>
        </>
        <>
          <a
            href="tel:07852466939"
            className="text-white text-2xl md:hidden flex flex-row gap-4 text-center items-center active:bg-creamDarker"
          >
            <BsFillTelephoneFill className="text-white sm:text-2xl md:text-2xl" />
          </a>
          <a
            href="tel:07852466939"
            className="text-white md:flex hidden md:text-2xl flex-row gap-4 text-center items-center rounded-lg hover:text-black group hover:bg-cream p-2 transition active:bg-creamDarker"
          >
            <BsFillTelephoneFill className="text-white sm:text-xl md:text-2xl group-hover:text-black" />
            07852 466939
          </a>
        </>
      </div>
    </nav>
  );
}

export default Navbar;
