import { Carousel } from "flowbite-react";
import { BiBriefcase } from "react-icons/bi";

function Portfolio() {
  return (
    <div className="h-screen bg-electrical-cover bg-cover">
      <div className="h-screen bg-black bg-opacity-[85%]">
        <p className="relative top-5 text-5xl z-10 text-center text-white pt-10 font-semibold flex flex-row gap-2 sm:gap-4 items-center justify-center">
          <BiBriefcase />
          Portfolio
        </p>
        <div className="h-[90%] flex flex-col items-center justify-center">
          <Carousel
            className="hidden md:block w-[60%] h-[80%] max-w-[900px] max-h-[900px] mx-auto px-20"
            slideInterval={5000}
            slide={false}
          >
            <img
              alt="..."
              src="/images/portfolio/img0.jpg"
              className="object-scale-down"
            />
            <img alt="..." src="/images/portfolio/img1.jpg" />
            <img alt="..." src="/images/portfolio/img2.jpg" />
            <img alt="..." src="/images/portfolio/img3.jpg" />
            <img alt="..." src="/images/portfolio/img4.jpg" />
            <img alt="..." src="/images/portfolio/img5.jpg" />
            <img alt="..." src="/images/portfolio/img6.jpg" />
            <img alt="..." src="/images/portfolio/img7.jpg" />
            <img alt="..." src="/images/portfolio/img8.jpg" />
            <img alt="..." src="/images/portfolio/img9.jpg" />
            <img alt="..." src="/images/portfolio/img10.jpg" />
          </Carousel>
          <Carousel
            className="h-screen md:hidden"
            indicators={false}
            slideInterval={5000}
          >
            <img alt="..." src="/images/portfolio/img0.jpg" />
            <img alt="..." src="/images/portfolio/img1.jpg" />
            <img alt="..." src="/images/portfolio/img2.jpg" />
            <img alt="..." src="/images/portfolio/img3.jpg" />
            <img alt="..." src="/images/portfolio/img4.jpg" />
            <img alt="..." src="/images/portfolio/img5.jpg" />
            <img alt="..." src="/images/portfolio/img6.jpg" />
            <img alt="..." src="/images/portfolio/img7.jpg" />
            <img alt="..." src="/images/portfolio/img8.jpg" />
            <img alt="..." src="/images/portfolio/img9.jpg" />
            <img alt="..." src="/images/portfolio/img10.jpg" />
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
